import axios from 'axios'
import { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Image, DropdownButton, Dropdown, Button } from 'react-bootstrap'
import Loader from '../Common/Loader'
import { RenderBreak } from '../../helpers/Helper'
import Autocomplete from '../Common/Autocomplete'
import JapanMap from '../SkiArea/JapanMap'

const SkiAreaHome = () => {

    const location = useLocation()

    const [skiAreaHomePage, setSkiAreaHomePage] = useState('')

    const [skiAreaList, setSkiAreaList] = useState([])
    const [prefectureList, setPrefectureList] = useState([])
    const [areaList, setAreaList] = useState([])
    const [townList, setTownList] = useState([])

    //const history = useHistory()
    const navigate = useNavigate()

    const fetchData = async () => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/skiarea/home/list`
        }).then(dataResortList => {
            setSkiAreaList(dataResortList.data)
            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/prefecture/list`
            })
        }).then(resPrefecture => {
            setPrefectureList(resPrefecture.data)
            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/area/list`
            })
        }).then(resArea => {
            setAreaList(resArea.data)
            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/town/list`
            })
        }).then(resTown => {
            setTownList(resTown.data)
            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/generalmessage/SkiArea`
            })
        }).then(resGeneralMessages => {
            setSkiAreaHomePage(resGeneralMessages.data.Message)
        })
    };

    useEffect(() => {
        fetchData()
    }, [location.pathname]);

    const ResortSelected = (evtKey, evt) => {

        let selectedSkiArea = skiAreaList.filter(function (currentElement) {
            return (currentElement.UniqueName === evtKey);
        });

        let path = window.$baseSkiAreaUrl + '/' + selectedSkiArea[0].PrefectureUniqueName + '/' + selectedSkiArea[0].TownUniqueName + '/' + selectedSkiArea[0].UniqueName;
        //history.push(path);
        navigate(path);
    }

    const PrefectureSelected = (evtKey, evt) => {
        let path = window.$baseSkiAreaUrl + '/prefecture/' + evtKey;
        //history.push(path);
        navigate(path);
    }

    const AreaSelected = (evtKey, evt) => {
        let path = window.$baseSkiAreaUrl + '/area/' + evtKey;
        //history.push(path);
        navigate(path);
    }

    const TownSelected = (evtKey, evt) => {
        let path = window.$baseSkiAreaUrl + '/town/' + evtKey;
        //history.push(path);
        navigate(path);
    }

    if (skiAreaHomePage === '') {
        return (
            <Loader />
        )

    } else {
        return (
            <div className='empty-row'>
                <Helmet>
                    <title>An introduction to the ski areas of Japan | SnowJapan</title>
                    <meta name="description" content="There are over 450 ski areas in Japan, from huge resorts with modern facilities to tiny local ski hills. SnowJapan introduces them all in detail." />
                </Helmet>

                <h3>Discover Japan's ski areas</h3>
                <div className="sj-para-image mb-4"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-SkiArea.svg" alt="Japan Ski Areas" /></div>
                <div className="sj-para">
                    <div dangerouslySetInnerHTML={{ __html: RenderBreak(skiAreaHomePage) }} />
                </div>
                <div className="sj-para">
                    <h5>Quick search (ski area, town, area)</h5>
                    <Autocomplete skiAreaList={skiAreaList} />
                </div>
                <div className='sj-row sj-box sj-box-dark-gray sj-round-half mb-3 center-aligned mt-5'>
                    <Link to='/japan-ski-resorts/discover'><h4>Search, sort & compare</h4></Link>
                </div>
                <div className="row sj-no-bg sj-row-no-margin-padding">
                    <div className="col-md-3 m-0 mt-2 p-0 sj-list-icon-holder">
                        <Link to='/japan-ski-resorts/discover'>
                            <Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/guide-ski-boom.svg' fluid='true' alt='Ski Area' />
                        </Link>
                    </div>
                    <div className="col-md-9">
                        This new tool lets you compare ski areas throughout Japan. You can filter and sort the list of ski areas by prefecture, town, area, or any combination of those. Results can then be sorted by ski area name, max elevation, min elevation, vertical, number of ski lifts, number of ski courses, longest course and steepest course.
                    </div>
                </div>
                <div className="sj-row sj-row-no-margin-padding">
                    <div className="row sj-row-no-margin-padding sj-no-bg">
                        <div className="col-lg-6 sj-row-no-margin-padding">
                            <div className='sj-row-100 sj-row-100-left'>
                                <DropdownButton align="start" title="Choose individual ski area (A-Z)" onSelect={ResortSelected} id="resort-list-a-z" className='sj-large-font-button'>
                                    {skiAreaList.map(skiArea => (
                                        <Dropdown.Item eventKey={skiArea.UniqueName} key={skiArea.UniqueName}>{skiArea.Name} ({skiArea.TownName} {skiArea.TownTypeStr}, {skiArea.PrefectureName})</Dropdown.Item>
                                    ))}
                                </DropdownButton>
                            </div>
                        </div>
                        <div className="col-lg-6 sj-row-no-margin-padding">
                            <div className='sj-row-100 sj-row-100-right'>
                                <DropdownButton align="start" title="Ski areas by prefecture" onSelect={PrefectureSelected} id="prefecture-list" className='sj-large-font-button '>
                                    {prefectureList.map(prefecture => (
                                        <Dropdown.Item eventKey={prefecture.UniqueName} key={prefecture.UniqueName}>{prefecture.Name} ({prefecture.TotalResorts})</Dropdown.Item>
                                    ))}
                                </DropdownButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sj-row sj-row-no-margin-padding">
                    <div className="row sj-row-no-margin-padding sj-no-bg">
                        <div className="col-lg-6 sj-row-no-margin-padding">
                            <div className='sj-row-100 sj-row-100-left'>
                                <DropdownButton align="start" title="Ski areas by popular area" onSelect={AreaSelected} id="area-list" className='sj-large-font-button'>
                                    {areaList.map(area => (
                                        <Dropdown.Item eventKey={area.UniqueName} key={area.UniqueName}>{area.Name} ({area.TotalResorts})</Dropdown.Item>
                                    ))}
                                </DropdownButton>
                            </div>
                        </div>
                        <div className="col-lg-6 sj-row-no-margin-padding">
                            <div className='sj-row-100 sj-row-100-right'>
                                <DropdownButton align="start" title="Ski areas by town" onSelect={TownSelected} id="town-list" className='sj-large-font-button '>
                                    {townList.map(town => (
                                        <Dropdown.Item eventKey={town.UniqueName} key={town.UniqueName}>{town.Name} {town.TownTypeStr} ({town.TotalResorts})</Dropdown.Item>
                                    ))}
                                </DropdownButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='sj-row'>
                    <JapanMap />
                </div>
                <div className="sj-para">
                    <div className='sj-box sj-box-dark-gray sj-round-half mb-3 center-aligned'>
                        <Link to='/japan-ski-resorts/reviews'><h4>Reviews</h4></Link>
                    </div>
                    <div className="row sj-no-bg sj-row-no-margin-padding">
                        <div className="col-md-3 m-0 mt-2 p-0 sj-list-icon-holder">
                            <Link to='/japan-ski-resorts/reviews'>
                                <Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Reviews.svg' fluid='true' alt='Reviews' />
                            </Link>
                        </div>
                        <div className="col-md-9">
                            <div className='mb-2'>Reviews of ski areas from around Japan submitted by SnowJapan readers. Reviews of specific ski areas are available in the relevant ski area section.</div>
                            <div>
                                <div className="row sj-row sj-no-bg sj-row-no-margin-padding">
                                    <div className="col-md-6 sj-row-no-margin-padding padding-right-5">
                                        <Link to={`${window.$baseSkiAreaUrl}/reviews`} className='btn btn-primary w-100 sj-box-dark-blue mt-2'><h6 className='mt-1 mb-1'>Read reviews</h6></Link>
                                    </div>
                                    <div className="col-md-6 sj-row-no-margin-padding padding-left-5">
                                        <Link to={`${window.$baseMemberUrl}/submit-review`} className='btn btn-primary sj-large-font-button sj-red-bg w-100 mt-2'><h6 className='mt-1 mb-1'>Write a new ski area review</h6></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sj-para mt-5">
                    <div className='sj-box sj-box-dark-gray sj-round-half mb-3 center-aligned'>
                        <Link to='/japan-ski-resorts/photos'><h4>Photos</h4></Link>
                    </div>
                    <div className="row sj-no-bg sj-row-no-margin-padding">
                        <div className="col-md-3 m-0 mt-2 p-0 sj-list-icon-holder">
                            <Link to='/japan-ski-resorts/photos'>
                                <Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Photos.svg' fluid='true' alt='Photos' />
                            </Link>
                        </div>
                        <div className="col-md-9">
                            <div className='mb-2'>Photos of ski areas from around Japan submitted by SnowJapan readers. Photos of specific ski areas are available in the relevant ski area section.</div>
                            <div>
                                <div className="row sj-row sj-no-bg sj-row-no-margin-padding">
                                    <div className="col-md-6 sj-row-no-margin-padding padding-right-5">
                                        <Link to={`${window.$baseSkiAreaUrl}/photos`} className='btn btn-primary w-100 sj-box-dark-blue mt-2'><h6 className='mt-1 mb-1'>View photos</h6></Link>
                                    </div>
                                    <div className="col-md-6 sj-row-no-margin-padding padding-left-5">
                                        <Link to={`${window.$baseMemberUrl}/upload-photo`} className='btn btn-primary sj-large-font-button sj-red-bg w-100 mt-2'><h6 className='mt-1 mb-1'>Upload new ski area photos</h6></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sj-para mt-5">
                    <div className='sj-box sj-box-dark-gray sj-round-half mb-3 center-aligned'>
                        <Link to={`${window.$baseRouteFinderUrl}`}><h4>Route Finder</h4></Link>
                    </div>
                    <div className="row sj-no-bg sj-row-no-margin-padding">
                        <div className="col-md-3 m-0 mt-2 p-0 sj-list-icon-holder">
                            <Link to={`${window.$baseRouteFinderUrl}`}>
                                <Image src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Route-finder.svg" alt="Japan Ski Areas Route Finder" fluid='true' />
                            </Link>
                        </div>
                        <div className="col-md-9">
                            <div>Discover the route and direction to any ski area in Japan from your current location or plan a trip between various Japanese ski areas.</div>
                        </div>
                    </div>
                </div>
                <div className="sj-para mt-5">
                    <div className='sj-box sj-box-dark-gray sj-round-half mb-3 center-aligned'>
                        <Link to='/guides/graphs'><h4>Japan's ski areas in graphs</h4></Link>
                    </div>
                    <div className="row sj-no-bg sj-row-no-margin-padding">
                        <div className="col-md-3 m-0 mt-2 p-0 sj-list-icon-holder">
                            <Link to='/guides/graphs'>
                                <Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Graphs-Data.svg' fluid='true' alt='Graphs' />
                            </Link>
                        </div>
                        <div className="col-md-9">
                            <div>Compare various ski area and ski lift data for Japan and each prefecture.</div>
                        </div>
                    </div>
                </div>
                <div className="banner-holder">
                    <div className="sj-row mb-0">
                        <div className="row sj-row-no-margin-padding sj-no-bg">
                            <div className="col-lg-3 col-md-6 sj-row-no-margin-padding">
                                <div className='home-listing-holder-left mt-3'>
                                    <a href='https://gala.co.jp/winter/english/' target='_blank' rel='noreferrer'><Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Listings/listing-gala-yuzawa.jpg' fluid='true' alt='listing-gala-yuzawa' /></a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 sj-row-no-margin-padding">
                                <div className='home-listing-holder-center1 mt-3'>
                                    <Link to='/listings/resort-spotlights/geto-kogen'><Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Listings/listing-geto-kogen.jpg' fluid='true' alt='listing-geto-kogen.jpg' /></Link>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 sj-row-no-margin-padding">
                                <div className='home-listing-holder-center2 mt-3'>
                                    <a href='https://wheelshokkaido.com/' target='_blank' rel='noreferrer'><Image src='https://www.snowjapan.com/UploadedFiles/ListingImages/link-wheels-transfers-hokkaido.png' fluid='true' alt='wheels-transfers-hokkaido' /></a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 sj-row-no-margin-padding">
                                <div className='home-listing-holder-right mt-3'>
                                    <Link to='/listings/resort-spotlights/white-world-oze-iwakura'><Image src='https://www.snowjapan.com/UploadedFiles/ListingImages/link-white-world-oze-iwakura.jpg' fluid='true' alt='white-world-oze-iwakura' /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sj-row mb-0">
                        <div className="row sj-row-no-margin-padding sj-no-bg">
                            <div className="col-lg-3 col-md-6 sj-row-no-margin-padding">
                                <div className='home-listing-holder-left mt-3'>
                                    <a href='https://www.hakunori.com/' target='_blank' rel='noreferrer'><Image src='https://www.snowjapan.com/UploadedFiles/ListingImages/link-hakuba-norikura.jpg' fluid='true' alt='hakuba-norikura' /></a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 sj-row-no-margin-padding">
                                <div className='home-listing-holder-center1 mt-3'>
                                    <a href='https://ishiuchi.or.jp/en/' target='_blank' rel='noreferrer'><Image src='https://www.snowjapan.com/UploadedFiles/ListingImages/link-ishiuchi-maruyama.jpg' fluid='true' alt='ishiuchi-maruyama' /></a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 sj-row-no-margin-padding">
                                <div className='home-listing-holder-center2 mt-3'>
                                    {/*<a href='https://www.snowjapan.com/listings/travel/yuzawa-minamiuonuma-eleven-plus' target='_blank' rel='noreferrer'><Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Listings/listing-eleven-plus.jpg' alt='listing-naspa' /></a>*/}
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 sj-row-no-margin-padding">
                                <div className='home-listing-holder-right mt-3'>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default SkiAreaHome;